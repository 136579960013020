import React from 'react'
import { HeroIcons } from '../../lib/utils/heroIcons'
import Card from '../Card'
import ProgressBar from './ProgressBar'
import { Property } from '../../types/Property/Property'
import {
  formatCurrency,
  formatNumber,
  formatPercentage,
} from '../../lib/utils/formatNumber'
import Link from 'next/link'
import Badge from './Badge'
import { useTranslation } from 'next-i18next'
import routes from '../../config/routes'
import useCurrency from '../../hooks/useCurrency'
import useLocale from '../../hooks/useLocale'
import ContributorsCount from './property/BuyingSection/ContributorsCount'

interface PropertyProps {
  property: Property
}

export default function PropertyCard({ property }: PropertyProps) {
  const { t } = useTranslation('common')
  const { currency } = useCurrency()
  const { locale } = useLocale()

  return (
    <Link
      href={{ pathname: routes.property.replace('[propertyId]', property.id) }}
    >
      <Card className="border-1 relative border-neutralGray/30 text-neutralBlack shadow-sm transition duration-500 ease-in-out hover:cursor-pointer hover:shadow-md">
        <div className="absolute z-10 p-3">
          <Badge fundingStatus={property.fundingStatus} />
        </div>
        <div className="relative aspect-[16/9] w-full flex-shrink-0 flex-grow-0 p-2">
          {property && property.images && property.images.length > 0 ? (
            <img
              src={property.images[0].url}
              className="z-10 aspect-[16/9] h-full w-full rounded-2xl bg-neutralGray/30 object-cover"
              alt={property.addressStreet + ' photo'}
            />
          ) : (
            <></>
          )}
          <HeroIcons.HomeIcon className="absolute left-1/2 top-1/2 -z-10 w-12 -translate-x-1/2 -translate-y-1/2 transform" />
        </div>
        <div className="px-3 pb-1">
          <div className="text-left">
            <div className="border-b-2 border-neutralGray/20">
              <div className="flex text-sm font-thin text-neutralBlack">
                {property.translation.title}
              </div>

              <div className="py-2 text-3xl">
                {formatCurrency(
                  property.fractionCount * property.fractionInitialPrice,
                  0,
                  true,
                  currency,
                  locale,
                )}
                <div className="text-sm">
                  {formatNumber(property.fractionCount)}{' '}
                  {t('propertyCard.pricePerFraction')}{' '}
                  <strong>
                    {formatCurrency(
                      property.fractionInitialPrice,
                      0,
                      true,
                      currency,
                      locale,
                    )}
                  </strong>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-3 text-left">
            <div className="border-b-2 border-neutralGray/20 pb-3">
              <div className="mb-1 flex text-sm">
                <HeroIcons.ChartBarIcon
                  className="mr-1 h-5 w-5"
                  aria-hidden="true"
                />
                {t('propertyCard.estimatedPriceReassessment')}{' '}
                <div className="text-neutraBlack ml-1 font-bold">
                  {formatPercentage(property.revaluationPerYear, 2, true)}
                </div>
              </div>
              <div className="flex text-sm ">
                <HeroIcons.BanknotesIcon
                  className="mr-1 h-5 w-5 "
                  aria-hidden="true"
                />
                {t('propertyCard.estimatedRentalYields')}{' '}
                <div className="ml-1 font-bold ">
                  {formatPercentage(property.rentalYieldPerYear, 2, true)}
                </div>
              </div>
            </div>
          </div>

          <div className="text-left">
            <div>
              <div>
                {property.fundingStatus !== 'funded' && (
                  <div className="mb-1 pt-3 text-sm">
                    {t('propertyCard.financingProgress.title')}
                    <div className="mt-1">
                      <ProgressBar
                        fractionCount={property.fractionCount}
                        fractionFunded={property.fractionFunded}
                        id={property.id}
                        lineHeight={1}
                      />
                    </div>
                  </div>
                )}

                <ContributorsCount property={property} />
              </div>
            </div>
          </div>
        </div>
      </Card>
    </Link>
  )
}
