import React, { useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { HeroIcons } from '../../../../lib/utils/heroIcons'
import propertyService from '../../../../services/property.service'
import { Property } from '../../../../types/Property/Property'

type ContributorsCountProps = {
  property: Property
  smallText?: boolean
}

export default function ContributorsCount({
  smallText,
  property,
}: ContributorsCountProps) {
  const { t } = useTranslation('originals/global')

  const [contributorsCount, setContributorsCount] = useState<number>(0)
  const [contributorsCountLoading, setContributorsCountLoading] =
    useState<boolean>(true)

  const fetchContributorsCount = async (propertyId: string) => {
    setContributorsCountLoading(true)
    const contributors = await propertyService.getContributorsCount(propertyId)
    setContributorsCount(contributors.count)
    setContributorsCountLoading(false)
  }

  useEffect(() => {
    fetchContributorsCount(property.id)
  }, [property])

  if (property.fundingStatus === 'funded') {
    return (
      <div className={`${smallText ? `text-xs` : 'text-sm'} flex pt-1.5`}>
        <HeroIcons.UserGroupIcon
          className={`${
            smallText ? `h-4 w-4` : 'h-5 w-5'
          } mr-1.5 text-neutralBlack/90`}
        />
        <p className="text-neutralBlack/90">
          {t('financingProgress.contribution.financedBy')}{' '}
          <span className="font-medium">{contributorsCount}</span>{' '}
          {t('financingProgress.contribution.contributors')}
        </p>
      </div>
    )
  }

  return (
    <>
      {contributorsCountLoading ? (
        <></>
      ) : (
        <div className={`${smallText ? `text-xs` : 'text-sm'} flex pt-1.5`}>
          <HeroIcons.UserGroupIcon
            className={`${
              smallText ? `h-4 w-4` : 'h-5 w-5'
            } mr-1.5 text-neutralBlack/90`}
          />
          {contributorsCount === 0 && (
            <p className="text-neutralBlack/90">
              {t('financingProgress.contribution.beTheFirstContributor')}
            </p>
          )}
          {contributorsCount === 1 && (
            <p className="text-neutralBlack/90">
              {t('financingProgress.contribution.already')}{' '}
              <span className="font-medium">{contributorsCount}</span>{' '}
              {t('financingProgress.contribution.contributor')}
            </p>
          )}
          {contributorsCount > 1 && (
            <p className="text-neutralBlack/90">
              {t('financingProgress.contribution.joinThe')}{' '}
              <span className="font-medium">{contributorsCount}</span>{' '}
              {t('financingProgress.contribution.contributors')}
            </p>
          )}
        </div>
      )}
    </>
  )
}
